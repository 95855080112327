import React, { useEffect, useState } from "react";
import { Card, Form, Input, Select, Row, Col, Button } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { postReq } from "../api";
import { toastNotification } from "../utils/toasthelper";

const { Option } = Select;

const UpdateInvoiceForm = () => {
  const [form] = Form.useForm();
  const location = useLocation();

  const history = useHistory();
  const [candidateData, setCandidateData] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [backendData, setBackendData] = useState({
    TotalWorkingHour: 0,
    totalBudget: 0,
  });
  const [concernMessage, setConcernMessage] = useState([]);
  console.log(location.state);
  useEffect(() => {
    // Load data from location.state
    if (location.state) {
      const {
        invoiceNo,
        gstNo,
        candidateId,
        month,
        totalHours,
        totalBudget,
        totalDays,
        monthlyHours,
        ratePerHour,
        totalAmount,
        concernMessage,
      } = location.state.record;

      form.setFieldsValue({
        invoiceNumber: invoiceNo,
        gstNumber: gstNo,
        candidate: { value: candidateId._id, name: candidateId.value },
        month,
        totalHours,
        totalBudget,
        totalDays,
        monthlyHours,
        ratePerHour,
        totalAmount,
        concernMessage: concernMessage || [],
      });

      setSelectedCandidate(candidateId);
      setSelectedMonth(month);
      setConcernMessage(concernMessage || []);
    } else {
      toastNotification("No data provided for update.", "error");
      history.push("/vendor/update_invoices");
    }
  }, [location.state, form, history]);

  useEffect(() => {
    fetchCandidate();
  }, []);

  useEffect(() => {
    if (selectedCandidate && selectedMonth) fetchBackendData();
  }, [selectedCandidate, selectedMonth]);

  const fetchCandidate = async () => {
    try {
      const results = await postReq("vendor/candidate", {
        limit: 10,
        page: 1,
      });
      if (results.status === 1) {
        setCandidateData(results.data.users);
      } else {
        toastNotification(results.message);
      }
    } catch (error) {
      toastNotification(error.message);
    }
  };

  const fetchBackendData = async () => {
    if (selectedCandidate && selectedMonth) {
      try {
        const results = await postReq("vendor/invoice-data", {
          userId: selectedCandidate,
          month: selectedMonth,
        });
        if (results.status === 1) {
          setBackendData(results.data);
          updateFormValues(
            results.data.TotalWorkingHour,
            results.data.totalBudget
          );
        } else {
          toastNotification(results.message);
        }
      } catch (error) {
        toastNotification(error.message);
      }
    }
  };

  const updateFormValues = (totalHours, totalBudget) => {
    const year = new Date().getFullYear();
    const monthIndex =
      new Date(Date.parse(selectedMonth + " 1, " + year)).getMonth() + 1;
    const totalMonthlyDay = new Date(year, monthIndex, 0).getDate();
    const totalMonthlyHours = totalMonthlyDay * 8;
    const ratePerHour = (totalBudget / totalMonthlyHours).toFixed(2);
    const totalAmount = (totalHours * ratePerHour).toFixed(2);

    form.setFieldsValue({
      totalHours,
      totalBudget,
      totalDays: totalMonthlyDay,
      monthlyHours: totalMonthlyHours,
      ratePerHour: ratePerHour,
      totalAmount: totalAmount,
    });
  };

  const handleFormSubmit = async (values) => {
    const payload = {
      _id: location.state.record._id,
      concernMessage: location.state.record.concernMessage,
      status: "pending",
      invoiceNumber: values.invoiceNumber,
      gstNumber: values.gstNumber,
      candidateId: selectedCandidate,
      month: values.month,
      year: new Date().getFullYear(),
      totalHours: values.totalHours,
      totalBudget: values.totalBudget,
      totalDays: parseInt(values.totalDays, 10) || 0,
      monthlyHours: parseFloat(values.monthlyHours),
      ratePerHour: parseFloat(values.ratePerHour),
      totalAmount: parseFloat(values.totalAmount),
    };

    try {
      const response = await postReq("vendor/update-invoice", payload);
      if (response.status === 1) {
        toastNotification("Invoice updated successfully!", "success");
        history.push("/vendor/update_invoices"); // Redirect after successful update
      } else {
        toastNotification(response.message, "error");
      }
    } catch (error) {
      console.error("Error updating invoice:", error);
      toastNotification("Failed to update invoice.", "error");
    }
  };

  const handleMonthDayChange = (days, totalBudget, totalHours) => {
    const totalMonthlyHours = parseInt(days) * 8;
    const ratePerHour = (totalBudget / totalMonthlyHours).toFixed(2);
    const totalAmount = (totalHours * ratePerHour).toFixed(2);

    form.setFieldsValue({
      monthlyHours: totalMonthlyHours,
      ratePerHour: ratePerHour,
      totalAmount: totalAmount,
    });
  };
  console.log(concernMessage);

  return (
    <Card
      title="Update Invoice"
      bordered={false}
      style={{ borderRadius: "18px" }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        initialValues={{ holidayHours: 0 }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Candidate"
              name="candidate"
              rules={[
                { required: true, message: "Please select a candidate!" },
              ]}
            >
              <Select
                placeholder="Select Candidate"
                onChange={setSelectedCandidate}
                disabled
              >
                {candidateData.map((candidate) => (
                  <Option key={candidate._id} value={candidate._id}>
                    {candidate.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Month"
              name="month"
              rules={[{ required: true, message: "Please select a month!" }]}
            >
              <Select
                disabled
                placeholder="Select Month"
                onChange={setSelectedMonth}
              >
                {[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ].map((month, index) => (
                  <Option key={index + 1} value={month}>
                    {month}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* Remaining fields */}
          {/* Same as in your current component */}
          <Col span={12}>
            <Form.Item label="Total Hours" name="totalHours">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Total Budget" name="totalBudget">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="GST Number" name="gstNumber">
              <Input placeholder="Enter GST Number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Invoice Number" name="invoiceNumber">
              <Input placeholder="Enter Invoice Number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Total Days" name="totalDays">
              <Input
                onChange={(e) =>
                  handleMonthDayChange(
                    e.target.value,
                    backendData.totalBudget,
                    backendData.TotalWorkingHour
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Monthly Hours" name="monthlyHours">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Rate per Hour" name="ratePerHour">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Total Amount" name="totalAmount">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Col span={24}>
          {concernMessage.map((message, index) => {
            return (
              <Form.Item
                label={`Concern ${index + 1}`}
                name={["concernMessage", index]}
                key={index}
              >
                <Input value={message} disabled />
              </Form.Item>
            );
          })}
        </Col>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: "20px" }}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default UpdateInvoiceForm;
