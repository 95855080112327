import React, { useState } from "react";
import { Form, Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { postReq } from "../../api";

const UploadDocsForm = () => {
  const [ndaDoc, setNdaDoc] = useState(null);
  const [msaDoc, setMsaDoc] = useState(null);

  const handleFileChange = (info, setFileCallback) => {
    const { file } = info;
    const isValidFile = validateFile(file);
    if (isValidFile) {
      setFileCallback(file);
    }
  };

  const validateFile = (file) => {
    if (!file) return false;

    // Validate file type and size
    const allowedTypes = ["application/pdf", "application/msword"];
    const isAllowedType = allowedTypes.includes(file.type);
    const isSmallerThan2MB = file.size / 1024 / 1024 < 2;

    if (!isAllowedType) {
      message.error("Only PDF or Word documents are allowed!");
      return false;
    }

    if (!isSmallerThan2MB) {
      message.error("File size must be smaller than 2MB!");
      return false;
    }

    return true;
  };

  const handleFinish = async (values) => {
    const formData = new FormData();

    // Append form fields
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    // Append files
    if (ndaDoc) {
      formData.append("signedNda", ndaDoc);
    }
    if (msaDoc) {
      formData.append("signedMsa", msaDoc);
    }
    formData.append("approveId", "67459de49a2ee78a929f07c6");

    try {
      const response = await postReq(
        "/vendor/upload-signed-documents",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success(
        response?.data?.message || "Documents uploaded successfully!"
      );
    } catch (error) {
      message.error(
        error.response?.data?.message || "Failed to upload documents"
      );
    }
  };

  return (
    <Form
      onFinish={handleFinish}
      layout="vertical"
      style={{ maxWidth: "400px", margin: "auto" }}
    >
      {/* Upload NDA Doc */}
      <Form.Item
        name="ndaDoc"
        label="Upload NDA Doc"
        rules={[{ required: true, message: "Please upload the NDA document!" }]}
      >
        <Upload
          beforeUpload={() => false} // Prevent auto-upload
          onChange={(info) => handleFileChange(info, setNdaDoc)}
          maxCount={1}
          accept=".pdf,.doc,.docx"
        >
          <Button icon={<UploadOutlined />}>Choose File</Button>
        </Upload>
      </Form.Item>

      {/* Upload MSA Doc */}
      <Form.Item
        name="msaDoc"
        label="Upload MSA Doc"
        rules={[{ required: true, message: "Please upload the MSA document!" }]}
      >
        <Upload
          beforeUpload={() => false} // Prevent auto-upload
          onChange={(info) => handleFileChange(info, setMsaDoc)}
          maxCount={1}
          accept=".pdf,.doc,.docx"
        >
          <Button icon={<UploadOutlined />}>Choose File</Button>
        </Upload>
      </Form.Item>

      {/* Submit Button */}
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UploadDocsForm;
