import {
  Card,
  Space,
  Table,
  Tooltip,
  Modal,
  Button,
  Form,
  Input,
  message,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { postReq } from "../api";
import { toastNotification } from "../utils/toasthelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const { Option } = Select;

const Invoice = () => {
  const [data, setData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const userRole = localStorage.getItem("userRole");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    {
      userRole === "company" && fetchData();
    }
  }, []);

  useEffect(() => {
    if (vendorData.length > 0) {
      const firstVendor = vendorData[0]?.vendorId?._id;
      const currentDate = new Date();
      const previousMonth =
        currentDate.getMonth() === 0
          ? { month: 12, year: currentDate.getFullYear() - 1 }
          : { month: currentDate.getMonth(), year: currentDate.getFullYear() };
      setSelectedVendor(firstVendor);
      setSelectedMonth(previousMonth);
    }
  }, [vendorData]);

  useEffect(() => {
    if (userRole === "company") {
      if (selectedVendor && selectedMonth) {
        fetchInvoice(selectedVendor, selectedMonth);
      }
    } else {
      fetchInvoice();
    }
  }, [selectedVendor, selectedMonth]);

  const handleMonthChange = (value) => {
    const [month, year] = value.split("-");
    setSelectedMonth({ month: parseInt(month, 10), year: parseInt(year, 10) });
  };

  const [tableParams, setTableParams] = useState({
    page: 1,
    limit: 10,
  });
  const history = useHistory();
  const columns = [
    {
      title: "InvoiceNo",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Candidate Name",
      dataIndex: ["candidateId", "name"],
      key: "candidatename",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Month",
      key: "month",
      render: (_, record) => <p>{`${record.month} / ${record.year}`}</p>,
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Final Budget",
      dataIndex: "totalBudget",
      key: "totalBudget",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <div style={{ cursor: "pointer" }}>
            {/* <a href={record.resumeLink}> */}
            <Tooltip title="View Invoice">
              <svg
                width="20"
                height="24"
                viewBox="0 0 26 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25.1715 4.15063C25.1715 1.87448 23.2971 0 21.0209 0H10.3096C10.1758 0 10.0258 0.0111238 9.87146 0.0289251C8.99606 0.129871 8.12101 0.448022 7.49791 1.07113L2.4431 6.01606C2.28321 6.17247 2.11797 6.32552 1.95255 6.47874C1.35804 7.0294 0.761174 7.58225 0.401674 8.30126C0.133891 8.83682 0 9.37239 0 10.0418V27.8494C0 30.1255 1.87448 32 4.15063 32H21.0209C23.2971 32 25.1715 30.1255 25.1715 27.8494V4.15063ZM19.6129 3.2035C19.6129 2.84244 19.3202 2.54974 18.9591 2.54974C18.5981 2.54974 18.3054 2.84244 18.3054 3.2035V9.9084L16.7384 8.36077C16.4815 8.10705 16.0675 8.10962 15.8138 8.36651C15.5601 8.6234 15.5627 9.03734 15.8196 9.29106L18.9267 12.3598L19.2806 12.0104C19.3817 11.9532 19.466 11.8698 19.5245 11.7695L22.0339 9.29106C22.2908 9.03734 22.2933 8.6234 22.0396 8.36651C21.7859 8.10962 21.372 8.10705 21.1151 8.36077L19.6129 9.84441L19.6129 3.2035ZM12.6181 12.879C10.7787 12.879 9.28205 14.3793 9.28205 16.2233C9.28205 18.0672 10.7787 19.5675 12.6181 19.5675C14.4575 19.5675 15.9542 18.0672 15.9542 16.2233C15.9542 14.3793 14.4575 12.879 12.6181 12.879ZM6.47272 24.6719C6.47272 22.4401 8.28474 20.6236 10.5111 20.6236H14.7251C16.9515 20.6236 18.7635 22.4401 18.7635 24.6719C18.7635 25.74 17.8975 26.6081 16.8321 26.6081H8.40413C7.3387 26.6081 6.47272 25.74 6.47272 24.6719Z"
                  fill="#0072FF"
                />
              </svg>
            </Tooltip>
            {/* </a> */}
          </div>
          {userRole === "company" ? (
            <div style={{ cursor: "pointer" }}>
              {/* <a href={record.sowDocLink}> */}
              <Tooltip title="Raise Concern">
                <svg
                  width="20"
                  height="24"
                  viewBox="0 0 26 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleConcern(record)}
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.3209 0H4.64746C2.08069 0 0 2.08072 0 4.6474V26.8548C0 29.4215 2.08069 31.5022 4.64746 31.5022H21.3209C23.8876 31.5022 25.9683 29.4215 25.9683 26.8548V4.6474C25.9683 2.08072 23.8876 0 21.3209 0ZM19.587 25.9987H6.38123C5.78894 25.9987 5.30872 25.5185 5.30872 24.9262C5.30872 24.3339 5.78894 23.8537 6.38123 23.8537H19.587C20.1793 23.8537 20.6595 24.3339 20.6595 24.9262C20.6595 25.5185 20.1793 25.9987 19.587 25.9987ZM16.2999 20.1107H6.38123C5.78894 20.1107 5.30872 19.6305 5.30872 19.0382C5.30872 18.4459 5.78894 17.9658 6.38123 17.9658H16.2999C16.8922 17.9658 17.3724 18.4459 17.3724 19.0382C17.3724 19.6305 16.8922 20.1107 16.2999 20.1107ZM19.4409 2.9043C19.8019 2.9043 20.0946 3.19699 20.0946 3.55807V10.1994L21.5973 8.71524C21.8541 8.46152 22.2681 8.46408 22.5219 8.72098C22.7755 8.97787 22.7729 9.39182 22.5161 9.64551L20.0056 12.125C19.9474 12.2246 19.8635 12.3074 19.7632 12.3644L19.4089 12.7143L16.3018 9.64551C16.0449 9.39182 16.0424 8.97787 16.296 8.72098C16.5498 8.46408 16.9637 8.46152 17.2206 8.71524L18.7871 10.2624V3.55807C18.7871 3.19699 19.0798 2.9043 19.4409 2.9043Z"
                    fill="#13487D"
                  />
                </svg>
              </Tooltip>
              {/* </a> */}
            </div>
          ) : (
            <div style={{ cursor: "pointer" }}>
              {/* <a href={record.sowDocLink}> */}
              <Tooltip title="Resolve Concern">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() =>
                    history.push("/vendor/update_invoices", { record })
                  }
                >
                  <path
                    d="M16.625 13.608L11.6667 18.5663C11.2315 19.0003 10.6307 19.25 10.017 19.25H9.33333V18.5663C9.33333 17.9433 9.576 17.3577 10.017 16.9167L14.9753 11.9583L16.625 13.608ZM17.8418 9.09183L16.625 10.3087L18.2747 11.9583L19.4915 10.7415C19.9465 10.2865 19.9465 9.54683 19.4915 9.09183C19.0505 8.64967 18.2817 8.64967 17.8418 9.09183ZM28 5.83333V22.1667C28 25.3832 25.3832 28 22.1667 28H5.83333C2.61683 28 0 25.3832 0 22.1667V5.83333C0 2.61683 2.61683 0 5.83333 0H22.1667C25.3832 0 28 2.61683 28 5.83333ZM21.1423 7.44217C19.8193 6.12033 17.514 6.12033 16.1922 7.44217L8.36733 15.267C7.4865 16.1478 7 17.3203 7 18.5663V20.4167C7 21.0618 7.52267 21.5833 8.16667 21.5833H10.017C11.263 21.5833 12.4355 21.098 13.3163 20.216L21.1412 12.3912C22.5062 11.0262 22.5073 8.80717 21.1423 7.44217Z"
                    fill="#666666"
                  />
                </svg>
              </Tooltip>
              {/* </a> */}
            </div>
          )}
        </Space>
      ),
    },
  ];
  const [showModal, setShowModal] = useState(false);
  const [concernData, setConcerData] = useState({});
  const [formData, setFormData] = useState({
    text: "",
  });

  const handleConcern = (record) => {
    setShowModal(true);
    setConcerData(record);
  };

  const fetchInvoice = async (vendorId, selectedMonth) => {
    try {
      setLoading(true);
      let url = "";
      {
        userRole === "vendor"
          ? (url = "/vendor/get-invoice")
          : userRole === "company"
          ? (url = "/company/get-invoice")
          : (url = "");
      }

      const response = await postReq(url, { vendorId, selectedMonth });
      if (response.status === 1) {
        setData(response.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const fetchData = async () => {
    setLoading(true);
    try {
      const results = await postReq(
        "/company/get-vendor-dropdown",
        tableParams
      );
      if (results.status === 1) {
        setVendorData(results.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      toastNotification("error", error.message);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setConcerData({});
    setShowModal(false);
    setFormData({ text: "" });
  };

  const handleSubmit = async () => {
    try {
      const wordCount = formData.text.trim().split(/\s+/).length;

      if (wordCount < 20) {
        message.error("Please enter at least 20 words.");
      } else {
        const response = await postReq("/company/status-update-invoice", {
          _id: concernData?._id,
          concernMessage: formData.text,
        });

        if (response.status === 1) {
          toastNotification("success", response.message);
          setShowModal(false);
          setFormData({ text: "" });
          setConcerData({});
        } else {
          toastNotification("error", response?.message || "Error");
          setShowModal(false);
          setFormData({ text: "" });
          setConcerData({});
        }
      }
    } catch (error) {
      toastNotification("error", error?.message || "Error");
      setShowModal(false);
      setFormData({ text: "" });
      setConcerData({});
      console.error("Error submitting data:", error);
    }
  };
  const handleVendorChange = (value) => {
    setSelectedVendor(value);
  };

  return (
    <>
      <Modal
        className="custom-modal"
        title={
          <h2 style={{ margin: 0, fontWeight: "bold", color: "#13487D" }}>
            Submit Your Concern
          </h2>
        }
        open={showModal}
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel"
            onClick={handleCancel}
            style={{
              backgroundColor: "#E4E6EF",
              color: "#5E6278",
              border: "none",
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            style={{ backgroundColor: "#13487D", borderColor: "#13487D" }}
          >
            Submit
          </Button>,
        ]}
        bodyStyle={{
          padding: "18px",
          borderRadius: "24px",
        }}
        centered
      >
        <Form
          layout="vertical"
          style={{
            fontFamily: "Arial, sans-serif",
          }}
        >
          <Form.Item>
            <Input.TextArea
              rows={4}
              value={formData.text}
              placeholder="Enter your concern here"
              onChange={(e) => setFormData({ text: e.target.value })}
              style={{
                borderRadius: "8px",
                padding: "6px",
                fontSize: "14px",
                borderColor: "#D1D5DB",
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Card
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>Invoice</div>
            {userRole === "company" ? (
              <div>
                <Space>
                  <Select
                    placeholder="Select Vendor"
                    value={selectedVendor}
                    onChange={handleVendorChange}
                    style={{ width: 200 }}
                  >
                    {vendorData.map((data) => (
                      <Option key={data.vendorId._id} value={data.vendorId._id}>
                        {data.vendorId.name}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    placeholder="Select Month"
                    value={
                      selectedMonth
                        ? `${selectedMonth.month}-${selectedMonth.year}`
                        : undefined
                    }
                    onChange={handleMonthChange}
                    style={{ width: 150 }}
                  >
                    {Array.from({ length: 12 }, (_, i) => {
                      const date = new Date();
                      date.setMonth(i);
                      const monthName = date.toLocaleString("default", {
                        month: "long",
                      });
                      return (
                        <Option
                          key={i + 1}
                          value={`${i + 1}-${new Date().getFullYear()}`}
                        >
                          {monthName}
                        </Option>
                      );
                    })}
                  </Select>
                </Space>
              </div>
            ) : userRole === "vendor" ? (
              <>
                <svg
                  width="44"
                  height="44"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => history.push("/vendor/generate_invoices")}
                >
                  <rect width="64" height="64" rx="18" fill="#F4F4F4" />
                  <path
                    d="M32 22V42"
                    stroke="#13487D"
                    stroke-width="2.7"
                    stroke-linecap="round"
                  />
                  <path
                    d="M42 32L22 32"
                    stroke="#13487D"
                    stroke-width="2.7"
                    stroke-linecap="round"
                  />
                </svg>
              </>
            ) : null}
          </div>
        }
        bordered={false}
        style={{ borderRadius: "18px", maxWidth: "100%", overflow: "hidden" }}
      >
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={false}
          rowClassName={(record) =>
            record.status === "rejected" ? "highlighted-row" : ""
          }
          scroll={{ x: "max-content" }}
        />
      </Card>
    </>
  );
};

export default Invoice;
