import { useState, useEffect } from "react";

import {
  Row,
  Col,
  Breadcrumb,
  Badge,
  Dropdown,
  List,
  Avatar,
  Typography,
} from "antd";
import copy from "copy-to-clipboard";

import { HomeOutlined } from "@ant-design/icons";

import { NavLink } from "react-router-dom";
import styled from "styled-components";
import avtar from "../../assets/images/team-2.jpg";
import { toastNotification } from "../../utils/toasthelper";

const bell = [
  <svg
    width="19"
    height="23"
    viewBox="0 0 19 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.50085 0C10.1852 0 10.74 0.493136 10.74 1.10145V3.85507C10.74 4.46339 10.1852 4.95652 9.50085 4.95652C8.8165 4.95652 8.26172 4.46339 8.26172 3.85507V1.10145C8.26172 0.493136 8.8165 0 9.50085 0Z"
      fill="#13487D"
    />
    <path
      d="M17.3495 18.1756L1.65273 18.1756L1.65273 10.3272C1.65273 5.99263 5.04107 2.47879 9.22081 2.47879L9.7814 2.47879C13.9611 2.47879 17.3495 5.99263 17.3495 10.3272L17.3495 18.1756Z"
      fill="#13487D"
    />
    <path
      d="M6.60925 19.8279H12.3919C12.3919 21.1966 11.0974 22.3061 9.50056 22.3061C7.90373 22.3061 6.60925 21.1966 6.60925 19.8279Z"
      fill="#13487D"
    />
    <path
      d="M0 17.7624C0 17.078 0.500423 16.5232 1.11773 16.5232H17.8836C18.5009 16.5232 19.0013 17.078 19.0013 17.7624C19.0013 18.4468 18.5009 19.0017 17.8836 19.0017H1.11773C0.500423 19.0017 0 18.4468 0 17.7624Z"
      fill="#13487D"
    />
  </svg>,
];

const wifi = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 107 107"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="logo-spotify" fill="#2EBD59" fillRule="nonzero">
        <path
          d="M53.5,0 C23.9517912,0 0,23.9517912 0,53.5 C0,83.0482088 23.9517912,107 53.5,107 C83.0482088,107 107,83.0482088 107,53.5 C107,23.9554418 83.0482088,0.00365063118 53.5,0 Z M78.0358922,77.1597407 C77.0757762,78.7368134 75.0204708,79.2296486 73.4506994,78.2695326 C60.8888775,70.5922552 45.0743432,68.8582054 26.4524736,73.1111907 C24.656363,73.523712 22.8675537,72.3993176 22.458683,70.6032071 C22.0461617,68.8070966 23.1669055,67.0182873 24.9666667,66.6094166 C45.3444899,61.9548618 62.8273627,63.9590583 76.9297509,72.5745479 C78.4995223,73.5419652 78.9996588,75.5899693 78.0358922,77.1597407 L78.0358922,77.1597407 Z M84.5814739,62.5973729 C83.373115,64.5614125 80.8030706,65.1747185 78.8426817,63.9700102 C64.4664961,55.1318321 42.5408052,52.5727397 25.5325145,57.7347322 C23.3275333,58.4027977 20.9984306,57.1579324 20.3267144,54.9566018 C19.6622996,52.7516206 20.9071648,50.4261685 23.1084954,49.7544524 C42.5371546,43.858683 66.6933811,46.7134766 83.2051859,56.8622313 C85.1692255,58.0705902 85.7898328,60.636984 84.5814739,62.5973729 Z M85.1436711,47.4253497 C67.8980894,37.1853292 39.4523712,36.2434664 22.9880246,41.2375299 C20.3449676,42.0406687 17.5485841,40.5475606 16.7490959,37.9045036 C15.9496076,35.2614466 17.4390652,32.4650631 20.0857728,31.6619243 C38.9850904,25.9267827 70.3987718,27.0329239 90.2509041,38.8171614 C92.627465,40.2299556 93.4087001,43.3001365 91.9995565,45.6730467 C90.5940635,48.0532583 87.5165814,48.838144 85.1436711,47.4253497 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>,
];

const credit = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fill="#1890FF"
      d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
    ></path>
    <path
      fill="#1890FF"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
    ></path>
  </svg>,
];

const clockicon = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V10C9 10.2652 9.10536 10.5196 9.29289 10.7071L12.1213 13.5355C12.5118 13.9261 13.145 13.9261 13.5355 13.5355C13.9261 13.145 13.9261 12.5118 13.5355 12.1213L11 9.58579V6Z"
      fill="#111827"
    ></path>
  </svg>,
];

const data = [
  {
    title: "New message from Sophie",
    description: <>{clockicon} 2 days ago</>,

    avatar: avtar,
  },
  {
    title: "New album by Travis Scott",
    description: <>{clockicon} 2 days ago</>,

    avatar: <Avatar shape="square">{wifi}</Avatar>,
  },
  {
    title: "Payment completed",
    description: <>{clockicon} 2 days ago</>,
    avatar: <Avatar shape="square">{credit}</Avatar>,
  },
];

const menu = (
  <List
    min-width="100%"
    className="header-notifications-dropdown "
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item) => (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar shape="square" src={item.avatar} />}
          title={item.title}
          description={item.description}
        />
      </List.Item>
    )}
  />
);

function Header({
  placement,
  name,
  subName,
  onPress,
  handleSidenavColor,
  handleSidenavType,
  handleFixedNavbar,
  state,
}) {
  const { Text } = Typography;

  const [visible, setVisible] = useState(false);
  const [sidenavType, setSidenavType] = useState("transparent");
  const [companyId, setCompanyId] = useState("");

  useEffect(() => window.scrollTo(0, 0));

  const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);
  useEffect(() => {
    const userRole = localStorage.getItem("userRole");
    if (userRole === "company") {
      setCompanyId(JSON.parse(localStorage.getItem("companyDetails"))._id);
    } else {
    }
  }, []);
  const CopyOnbaordingLink = () => {
    let vendorPageLink =
      process.env.REACT_APP_URL + "auth/vendor-onboarding/" + companyId;
    copy(vendorPageLink);
    toastNotification(
      "success",
      "Vendor onboarding page link copy successfully."
    );
  };
  return (
    <>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        <Col
          span={32}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink
                to={
                  companyId !== ""
                    ? "/company/dashboard"
                    : "/vendor/requirement"
                }
              >
                <HomeOutlined />
              </NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
              {subName}
            </Breadcrumb.Item>
          </Breadcrumb>
          {state && (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6" r="6" fill="#13487D" />
                <circle cx="6" cy="6" r="2" fill="white" />
              </svg>
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#13487D",
                }}
              >
                {state?.data?.requirementName}
              </Text>
            </div>
          )}
        </Col>
        <Col className="header-control" style={{ gap: "10px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {companyId !== "" && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "150px", // Adjusted width for balance
                    padding: "8px 10px", // Padding for better spacing
                    backgroundColor: "#F1F3FA", // Light background as per the image
                    borderRadius: "20px 0px 0px 20px", // Rounded edges
                  }}
                  // onClick={() => CopyOnbaordingLink()}
                >
                  <p
                    style={{
                      margin: "0",
                      color: "#5E6278", // Gray color for text
                      fontSize: "14px", // Adjusted text size
                    }}
                  >
                    Vendor Onboarding
                  </p>
                </div>
                <div
                  style={{
                    width: "39px",
                    height: "39px",
                    background: "#13487D",
                    borderRadius: "0px 20px 20px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    // fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => CopyOnbaordingLink()}
                    style={{ cursor: "pointer" }}
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.3682 0.224121H7.37697C6.45981 0.224121 5.62788 0.722452 5.20799 1.48754C5.05241 1.80079 4.96472 2.15465 4.96472 2.53243V13.4726C4.96472 14.8078 6.0601 15.8444 7.37697 15.8444H14.6577C15.9746 15.8444 17.07 14.8078 17.07 13.4726V5.8441H13.4296C12.2882 5.8441 11.3682 4.97844 11.3682 3.81417V0.224121ZM8.2103 11.2504C8.2103 10.7901 8.58339 10.417 9.04364 10.417H10.6226C11.0828 10.417 11.456 10.7901 11.456 11.2504C11.456 11.7106 11.0828 12.0837 10.6226 12.0837H9.04364C8.58339 12.0837 8.2103 11.7106 8.2103 11.2504ZM9.04364 7.6818C8.58339 7.6818 8.2103 8.0549 8.2103 8.5151C8.2103 8.97535 8.58339 9.34844 9.04364 9.34844H13.0787C13.539 9.34844 13.9121 8.97535 13.9121 8.5151C13.9121 8.0549 13.539 7.6818 13.0787 7.6818H9.04364Z"
                      fill="white"
                    />
                    <path
                      d="M16.0835 4.17743L13.0349 1.20709V3.81418C13.0349 4.01743 13.1677 4.17743 13.4297 4.17743H16.0835Z"
                      fill="white"
                    />
                    <path
                      d="M0.929688 6.46392C0.929688 5.15848 1.97676 4.1385 3.2542 4.09366V13.5574C3.2542 15.762 5.05505 17.6173 7.37702 17.6173H13.0255C12.9162 18.848 11.8686 19.7759 10.6227 19.7759H3.34196C2.02507 19.7759 0.929688 18.7393 0.929688 17.4041V6.46392Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </>
            )}
          </div>
          <Dropdown overlay={menu} trigger={["click"]}>
            <div
              style={{
                background: "#F8F8F8",
                width: "42px",
                height: "42px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Badge size="small" count={4}>
                <a
                  href="#pablo"
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  {bell}
                </a>
              </Badge>
            </div>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
}

export default Header;
