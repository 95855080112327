import React, { useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Card,
  Typography,
  Row,
  Col,
  TimePicker,
  message,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { postReq } from "../api";
import { toastNotification } from "../utils/toasthelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Title } = Typography;

const TaskForm = ({ record }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([
    { id: 1, taskName: "", taskDesc: "", startTime: null, endTime: null },
  ]);

  const handleAddTask = () => {
    setTasks([
      ...tasks,
      {
        id: tasks.length + 1,
        taskName: "",
        taskDesc: "",
        startTime: null,
        endTime: null,
      },
    ]);
  };

  const handleRemoveTask = (id) => {
    if (tasks.length === 1) {
      message.warning("At least one task must be present.");
      return;
    }
    setTasks(tasks.filter((task) => task.id !== id));
  };

  const handleFinish = async (values) => {
    try {
      setLoading(true);
      const payload = {
        date: values.date ? values.date.toISOString() : null,
        projectName: values.projectName,
        tasks: tasks.map((task) => ({
          taskName: task.taskName,
          taskDesc: task.taskDesc,
          startTime: task.startTime ? task.startTime.toISOString() : null,
          endTime: task.endTime ? task.endTime.toISOString() : null,
        })),
      };
      const response = await postReq("/company/create-timesheet", payload);
      if (response.status === 1) {
        toastNotification("success", response.message);
        setLoading(false);
        history.push("/company/TimeSheet");
      } else {
        toastNotification("error", response.message);
        setLoading(false);
      }
    } catch (error) {
      toastNotification("error", error.message);
      setLoading(false);
    }
  };

  const handleTaskChange = (id, key, value) => {
    const updatedTasks = tasks.map((task) =>
      task.id === id ? { ...task, [key]: value } : task
    );
    setTasks(updatedTasks);
  };

  return (
    <Card
      title={
        <div>
          <Title
            level={4}
            style={{ marginBottom: 0, fontWeight: "bold", color: "#13487D" }}
          >
            Add Tasks
          </Title>
        </div>
      }
      bordered={false}
      style={{ borderRadius: "18px" }}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Select Date"
              name="date"
              rules={[{ required: true, message: "Please select a date." }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Project Name"
              name="projectName"
              rules={[
                { required: true, message: "Please enter the project name." },
              ]}
            >
              <Input placeholder="Enter Project Name" />
            </Form.Item>
          </Col>
        </Row>

        <div>
          {tasks.map((task, index) => (
            <Card
              key={task.id}
              style={{
                marginBottom: 16,
                border: "1px solid #d9d9d9",
                borderRadius: 8,
              }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Title level={5}>Task {index + 1}</Title>
                </Col>
                <Col span={12}>
                  <Form.Item label="Task Name" required>
                    <Input
                      placeholder="Enter Task Name"
                      value={task.taskName}
                      onChange={(e) =>
                        handleTaskChange(task.id, "taskName", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Start Time" required>
                    <TimePicker
                      format="HH:mm"
                      style={{ width: "100%" }}
                      value={task.startTime ? dayjs(task.startTime) : null}
                      onChange={(value) =>
                        handleTaskChange(task.id, "startTime", value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Task Description" required>
                    <Input.TextArea
                      placeholder="Enter Task Description"
                      value={task.taskDesc}
                      onChange={(e) =>
                        handleTaskChange(task.id, "taskDesc", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="End Time" required>
                    <TimePicker
                      format="HH:mm"
                      style={{ width: "100%" }}
                      value={task.endTime ? dayjs(task.endTime) : null}
                      onChange={(value) =>
                        handleTaskChange(task.id, "endTime", value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    type="danger"
                    icon={<MinusOutlined />}
                    onClick={() => handleRemoveTask(task.id)}
                  >
                    Remove Task
                  </Button>
                </Col>
              </Row>
            </Card>
          ))}
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={handleAddTask}
            style={{ width: "100%", marginTop: 16 }}
          >
            Add Task
          </Button>
        </div>

        <Form.Item style={{ marginTop: 24 }}>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default TaskForm;
