import React, { useEffect, useState } from "react";
import { Input, Row, Col, Typography, Card } from "antd";
import CardComponent from "../../components/cardComponent";
import { postReq } from "../../api";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

const VendorDashboard = () => {
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    searchParams: "",
    // status: "open",
  });
  const [jobData, setJobData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(true);

  const getRequirementList = async (newQuery) => {
    try {
      setIsLoading(true);
      const getRes = await postReq("vendor/requirement/list", newQuery);
      if (getRes.status === 1 && !Array.isArray(getRes.data)) {
        setJobData(getRes.data.requirements);
        console.log(getRes.data.requirements);
        setIsLoading(false);
      } else {
        setJobData([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error ", error);
      setJobData([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRequirementList(query);
  }, [query]);

  useEffect(() => {
    const handler = setTimeout(() => {
      getRequirementList(query);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [query.searchParams]);
  const handleOnChange = (e) => {
    setQuery({ ...query, searchParams: e.target.value });
  };

  return (
    <>
      <Card
        loading={isLoading}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px",
              borderRadius: "20px 20px 0px 0px",
              margin: "0px -24px",
            }}
          >
            <div>
              <Title
                level={4}
                style={{
                  marginBottom: 0,
                  fontSize: "20px",
                  fontWeight: "800",
                  color: "#13487D",
                }}
              >
                Requirements
              </Title>
              <Text type="secondary" style={{ fontSize: "14px" }}>
                Dedicated Project
              </Text>
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              {isSearch ? (
                <svg
                  width="44"
                  height="44"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setIsSearch(!isSearch)}
                >
                  <rect width="64" height="64" rx="18" fill="#F4F4F4" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M35.2267 37.1801L40.6511 42.5978C41.1882 43.1342 42.0603 43.134 42.5974 42.5975C43.1343 42.0607 43.1342 41.1893 42.5971 40.6527L37.1763 35.2389C38.2668 33.757 38.9114 31.9269 38.9114 29.9476C38.9114 25.0092 34.8985 21 29.9557 21C25.0129 21 21 25.0092 21 29.9476C21 34.8859 25.0129 38.8951 29.9557 38.8951C31.9255 38.8951 33.7474 38.2586 35.2267 37.1801ZM29.9557 23.7501C33.3793 23.7501 36.159 26.5271 36.159 29.9476C36.159 33.3681 33.3793 36.1452 29.9557 36.1452C26.5321 36.1452 23.7526 33.3681 23.7526 29.9476C23.7526 26.5271 26.5321 23.7501 29.9557 23.7501Z"
                    fill="#13487D"
                  />
                </svg>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#F4F4F4",
                    borderRadius: "10px",
                    // padding: "10px 16px",
                    width: "300px",
                    boxShadow: "0px 1px 5px rgba(0,0,0,0.1)",
                  }}
                >
                  <Input
                    placeholder="Search By Job ID"
                    bordered={false}
                    onChange={handleOnChange}
                    style={{
                      fontSize: "16px",
                      color: "#6F6F6F",
                      border: "none",
                    }}
                  />
                  <svg
                    width="44"
                    height="44"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setIsSearch(!isSearch)}
                  >
                    <rect width="64" height="64" rx="18" fill="#F4F4F4" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M35.2267 37.1801L40.6511 42.5978C41.1882 43.1342 42.0603 43.134 42.5974 42.5975C43.1343 42.0607 43.1342 41.1893 42.5971 40.6527L37.1763 35.2389C38.2668 33.757 38.9114 31.9269 38.9114 29.9476C38.9114 25.0092 34.8985 21 29.9557 21C25.0129 21 21 25.0092 21 29.9476C21 34.8859 25.0129 38.8951 29.9557 38.8951C31.9255 38.8951 33.7474 38.2586 35.2267 37.1801ZM29.9557 23.7501C33.3793 23.7501 36.159 26.5271 36.159 29.9476C36.159 33.3681 33.3793 36.1452 29.9557 36.1452C26.5321 36.1452 23.7526 33.3681 23.7526 29.9476C23.7526 26.5271 26.5321 23.7501 29.9557 23.7501Z"
                      fill="#13487D"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        }
        style={{ paddingLeft: "16px", color: "#13487D" }}
      >
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          {jobData.map((data, i) => (
            <Col
              className="gutter-row"
              xs={16}
              sm={16}
              md={8}
              lg={8}
              style={{ marginBottom: "20px" }}
            >
              <Link
                to={{
                  pathname: "/vendor/requirement_description",
                  state: {
                    data: data,
                  },
                }}
              >
                <CardComponent
                  key={i}
                  requirementName={data.requirementName}
                  jobId={data.jobId}
                  requirementType={data.requirementType}
                  maxExperience={data.maxExperience}
                  minExperience={data.minExperience}
                  jobLocation={data.jobLocation}
                  requiredTechnology={data.requiredTechnology}
                />
              </Link>
            </Col>
          ))}
        </Row>
      </Card>
    </>
  );
};

export default VendorDashboard;
