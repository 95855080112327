import { Button, Form, Input, message, Select, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { postReq } from "../../api";
import { useParams } from "react-router-dom";
const { Option } = Select;

const OnBoardPage = (companyId) => {
  const [techNicheOptions, setTechNicheOptions] = useState([]);
  const { form } = Form.useForm();
  const handleTagsData = async () => {
    try {
      const callResponse = await postReq("/common/getTags", {
        type: "techNiche",
        companyId: companyId.vendorId,
        // companyId: "6631dfd0eb64c21aab3760bc",
      });

      if (callResponse.status === 1) {
        setTechNicheOptions(callResponse.data);
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  useEffect(() => {
    handleTagsData();
  }, []);

  const [organizationLogo, setOrganizationLogo] = useState(null);
  const [gstStatementFile, setGstStatementFile] = useState(null);

  const handleFinish = async (values) => {
    const formData = new FormData();

    // Append form fields
    Object.keys(values).forEach((key) => {
      if (key !== "technologyNiche") {
        formData.append(key, values[key]);
      }
    });
    if (values.technologyNiche && values.technologyNiche.length > 0) {
      for (let i = 0; i < values.technologyNiche.length; i++) {
        formData.append(`technologyNiche[${i}]`, values.technologyNiche[i]);
      }
    }
    formData.append("companyId", "6631dfd0eb64c21aab3760bc");
    // Append files
    if (organizationLogo) {
      formData.append("organizationLogo", organizationLogo);
    }
    if (gstStatementFile) {
      formData.append("gstStatementFile", gstStatementFile);
    }

    try {
      const response = await postReq("/vendor/register", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      message.success(
        response.data.message || "Vendor registered successfully!"
      );
    } catch (error) {
      message.error(
        error.response?.data?.message || "Failed to register vendor"
      );
    }
  };

  const handleFileChange = (info, setFileCallback) => {
    const { file } = info;
    const isValidFile = validateFile(file);
    if (isValidFile) {
      setFileCallback(file);
    }
  };

  const validateFile = (file) => {
    if (!file) return false;

    // Validate file type and size
    const isPngOrPdf =
      file.type === "image/png" || file.type === "application/pdf";
    const isSmallerThan2MB = file.size / 1024 / 1024 < 2;

    if (!isPngOrPdf) {
      message.error("Only PNG or PDF files are allowed!");
      return false;
    }

    if (!isSmallerThan2MB) {
      message.error("File size must be smaller than 2MB!");
      return false;
    }

    return true;
  };

  const handleFinishFailed = () => {
    console.log("hereFailed");
  };
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      onFinishFailed={handleFinishFailed}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          { required: true, message: "Name is required" },
          { min: 3, message: "Minimum 3 characters" },
          { max: 50, message: "Maximum 30 characters" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="vendorEmail"
        rules={[
          { required: true, message: "Email is required" },
          { type: "email", message: "Wrong email format" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="GST Number"
        name="vendorCompanyGstNo"
        rules={[{ required: true, message: "GST number is required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Company Name"
        name="vendorCompanyName"
        rules={[
          { required: true, message: "Company name is required" },
          { min: 3, message: "Minimum 3 characters" },
          { max: 50, message: "Maximum 30 characters" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Company Strength"
        name="vendorCompanyStrength"
        rules={[
          { required: true, message: "Company strength is required" },
          { min: 2, message: "Company strength is too low" },
          { max: 7, message: "Company strength is too big" },
          {
            pattern: /^[0-9]+$/,
            message: "Company strength should only be in numbers",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Company Website URL"
        name="vendorCompanyWebsiteUrl"
        rules={[
          { required: true, message: "Company website URL is required" },
          {
            pattern:
              /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/gi,
            message: "Invalid website URL",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Technology Niche"
        name="technologyNiche"
        rules={[
          { required: true, message: "Technology niche is required" },
          { type: "array", min: 1, message: "At least one niche is required" },
          { type: "array", max: 3, message: "You can add at most 3 niches" },
        ]}
      >
        <Select mode="multiple" placeholder="Select up to 3 niches">
          {techNicheOptions.map((data) => {
            return <Option value={data._id}>{data.value}</Option>;
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="CIN Number"
        name="cinNO"
        rules={[{ required: true, message: "CIN Number is required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="CEO PAN Number"
        name="ceoPanNo"
        rules={[
          { required: true, message: "CEO PAN Number is required" },
          {
            pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
            message: "Invalid PAN format",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="CEO Aadhaar Number"
        name="ceoAdharNo"
        rules={[
          { required: true, message: "CEO Aadhaar Number is required" },
          {
            pattern: /^[2-9]{1}[0-9]{11}$/,
            message: "Invalid Aadhaar format",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="ISO Number"
        name="isoNo"
        rules={[{ required: true, message: "ISO Number is required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Communication Email"
        name="communicationEmail"
        rules={[
          { required: true, message: "Communication Email is required" },
          { type: "email", message: "Invalid email format" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="TAN Number"
        name="tanNo"
        rules={[{ required: true, message: "TAN number is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Msme Number"
        name="msmeNo"
        rules={[{ required: true, message: "msme number is required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Register Address"
        name="RegAddress"
        rules={[{ required: true, message: "Register address is required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Operational Address"
        name="operationalAddress"
        rules={[{ required: true, message: "Operational address is required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Organization Logo"
        name="organizationLogo"
        rules={[
          {
            required: true,
            message: "Please upload the logo",
          },
          // {
          //   validator: (_, value) =>
          //     value && value.type === "png"
          //       ? Promise.resolve()
          //       : Promise.reject("Only PNG images are allowed"),
          // },
        ]}
      >
        <Upload
          beforeUpload={() => false} // Prevent automatic upload
          onChange={(info) => handleFileChange(info, setOrganizationLogo)}
          accept="image/png"
        >
          <Button>Upload PNG</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        label="GST Statement File"
        name="gstStatementFile"
        rules={[{ required: true, message: "Please upload the GST statement" }]}
      >
        <Upload
          beforeUpload={() => false} // Prevent automatic upload
          onChange={(info) => handleFileChange(info, setGstStatementFile)}
          accept="application/pdf"
        >
          <Button>Upload PDF</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        label="ITR No 1"
        name="itrNo1"
        rules={[
          { required: true, message: "ITR number is required" },
          {
            pattern: /^[0-9]+$/,
            message: "ITR number should only contain numbers",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="ITR No 2"
        name="itrNo2"
        rules={[
          { required: true, message: "ITR number is required" },
          {
            pattern: /^[0-9]+$/,
            message: "ITR number should only contain numbers",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="ITR No 3"
        name="itrNo3"
        rules={[
          { required: true, message: "ITR number is required" },
          {
            pattern: /^[0-9]+$/,
            message: "ITR number should only contain numbers",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OnBoardPage;
