import React, { useEffect, useState } from "react";
import { Space, Table, Card, Tooltip } from "antd";
import { getReq, postReq } from "../api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Consultant = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const columns = [
    {
      title: "Name",
      dataIndex: ["vendorDetails", "name"],
      key: "name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Job Title",
      dataIndex: ["requirementDetails", "requirementName"],
      key: "jobtitle",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Job Type",
      dataIndex: ["requirementDetails", "requirementType"],
      key: "jobtype",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Job Location",
      dataIndex: ["requirementDetails", "jobLocation"],
      key: "joblocation",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Final Budget",
      dataIndex: "finalBudget",
      key: "finalbudget",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <div style={{ cursor: "pointer" }}>
            <a href={record.resumeLink}>
              <Tooltip title="Resume">
                <svg
                  width="15"
                  height="21"
                  viewBox="0 0 26 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M25.1715 4.15063C25.1715 1.87448 23.2971 0 21.0209 0H10.3096C10.1758 0 10.0258 0.0111238 9.87146 0.0289251C8.99606 0.129871 8.12101 0.448022 7.49791 1.07113L2.4431 6.01606C2.28321 6.17247 2.11797 6.32552 1.95255 6.47874C1.35804 7.0294 0.761174 7.58225 0.401674 8.30126C0.133891 8.83682 0 9.37239 0 10.0418V27.8494C0 30.1255 1.87448 32 4.15063 32H21.0209C23.2971 32 25.1715 30.1255 25.1715 27.8494V4.15063ZM19.6129 3.2035C19.6129 2.84244 19.3202 2.54974 18.9591 2.54974C18.5981 2.54974 18.3054 2.84244 18.3054 3.2035V9.9084L16.7384 8.36077C16.4815 8.10705 16.0675 8.10962 15.8138 8.36651C15.5601 8.6234 15.5627 9.03734 15.8196 9.29106L18.9267 12.3598L19.2806 12.0104C19.3817 11.9532 19.466 11.8698 19.5245 11.7695L22.0339 9.29106C22.2908 9.03734 22.2933 8.6234 22.0396 8.36651C21.7859 8.10962 21.372 8.10705 21.1151 8.36077L19.6129 9.84441L19.6129 3.2035ZM12.6181 12.879C10.7787 12.879 9.28205 14.3793 9.28205 16.2233C9.28205 18.0672 10.7787 19.5675 12.6181 19.5675C14.4575 19.5675 15.9542 18.0672 15.9542 16.2233C15.9542 14.3793 14.4575 12.879 12.6181 12.879ZM6.47272 24.6719C6.47272 22.4401 8.28474 20.6236 10.5111 20.6236H14.7251C16.9515 20.6236 18.7635 22.4401 18.7635 24.6719C18.7635 25.74 17.8975 26.6081 16.8321 26.6081H8.40413C7.3387 26.6081 6.47272 25.74 6.47272 24.6719Z"
                    fill="#0072FF"
                  />
                </svg>
              </Tooltip>
            </a>
          </div>
          <div style={{ cursor: "pointer" }}>
            <a href={record.sowDocLink}>
              <Tooltip title="view sow">
                <svg
                  width="15"
                  height="21"
                  viewBox="0 0 26 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.3209 0H4.64746C2.08069 0 0 2.08072 0 4.6474V26.8548C0 29.4215 2.08069 31.5022 4.64746 31.5022H21.3209C23.8876 31.5022 25.9683 29.4215 25.9683 26.8548V4.6474C25.9683 2.08072 23.8876 0 21.3209 0ZM19.587 25.9987H6.38123C5.78894 25.9987 5.30872 25.5185 5.30872 24.9262C5.30872 24.3339 5.78894 23.8537 6.38123 23.8537H19.587C20.1793 23.8537 20.6595 24.3339 20.6595 24.9262C20.6595 25.5185 20.1793 25.9987 19.587 25.9987ZM16.2999 20.1107H6.38123C5.78894 20.1107 5.30872 19.6305 5.30872 19.0382C5.30872 18.4459 5.78894 17.9658 6.38123 17.9658H16.2999C16.8922 17.9658 17.3724 18.4459 17.3724 19.0382C17.3724 19.6305 16.8922 20.1107 16.2999 20.1107ZM19.4409 2.9043C19.8019 2.9043 20.0946 3.19699 20.0946 3.55807V10.1994L21.5973 8.71524C21.8541 8.46152 22.2681 8.46408 22.5219 8.72098C22.7755 8.97787 22.7729 9.39182 22.5161 9.64551L20.0056 12.125C19.9474 12.2246 19.8635 12.3074 19.7632 12.3644L19.4089 12.7143L16.3018 9.64551C16.0449 9.39182 16.0424 8.97787 16.296 8.72098C16.5498 8.46408 16.9637 8.46152 17.2206 8.71524L18.7871 10.2624V3.55807C18.7871 3.19699 19.0798 2.9043 19.4409 2.9043Z"
                    fill="#13487D"
                  />
                </svg>
              </Tooltip>
            </a>
          </div>
          {!record.isCandidateOnBoardFound && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                history.push("/company/Candidate/create", {
                  record: {
                    appliedRequirementId: record.appliedRequirementId,
                    vendorId: record.applyDetails.vendorId,
                    finalBudget: record.finalBudget,
                  },
                })
              }
            >
              <Tooltip title="Candidate Onboard">
                <svg
                  width="15"
                  height="21"
                  viewBox="0 0 26 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.3209 0H4.64746C2.08069 0 0 2.08072 0 4.6474V26.8548C0 29.4215 2.08069 31.5022 4.64746 31.5022H21.3209C23.8876 31.5022 25.9683 29.4215 25.9683 26.8548V4.6474C25.9683 2.08072 23.8876 0 21.3209 0ZM19.587 25.9987H6.38123C5.78894 25.9987 5.30872 25.5185 5.30872 24.9262C5.30872 24.3339 5.78894 23.8537 6.38123 23.8537H19.587C20.1793 23.8537 20.6595 24.3339 20.6595 24.9262C20.6595 25.5185 20.1793 25.9987 19.587 25.9987ZM16.2999 20.1107H6.38123C5.78894 20.1107 5.30872 19.6305 5.30872 19.0382C5.30872 18.4459 5.78894 17.9658 6.38123 17.9658H16.2999C16.8922 17.9658 17.3724 18.4459 17.3724 19.0382C17.3724 19.6305 16.8922 20.1107 16.2999 20.1107ZM19.4409 2.9043C19.8019 2.9043 20.0946 3.19699 20.0946 3.55807V10.1994L21.5973 8.71524C21.8541 8.46152 22.2681 8.46408 22.5219 8.72098C22.7755 8.97787 22.7729 9.39182 22.5161 9.64551L20.0056 12.125C19.9474 12.2246 19.8635 12.3074 19.7632 12.3644L19.4089 12.7143L16.3018 9.64551C16.0449 9.39182 16.0424 8.97787 16.296 8.72098C16.5498 8.46408 16.9637 8.46152 17.2206 8.71524L18.7871 10.2624V3.55807C18.7871 3.19699 19.0798 2.9043 19.4409 2.9043Z"
                    fill="#13487D"
                  />
                </svg>
              </Tooltip>
            </div>
          )}
        </Space>
      ),
    },
  ];
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    console.log("here");
    setLoading(true);
    const results = await getReq("company/selected-candidates");
    setData(results.data);
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card
      title="Users"
      bordered={false}
      style={{ borderRadius: "18px", maxWidth: "100%", overflow: "hidden" }}
    >
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        scroll={{ x: "max-content" }}
        pagination={false}
      />
    </Card>
  );
};

export default Consultant;
