import React, { useState } from "react";
import { Form, Input, Button, Select, message } from "antd";
import { useHistory } from "react-router-dom";
import { postReq } from "../api";
import { toastNotification } from "../utils/toasthelper";

const { Option } = Select;

const CompanyRegistration = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleFormSubmission = async (data) => {
    try {
      const postData = await postReq("/company/register", data, {
        "Content-Type": "multipart/form-data",
      });
      if (postData.status === 1) {
        toastNotification("success", postData.message);
        setLoading(false);
        history.push("/auth/company/login");
      } else {
        toastNotification("error", postData.message);
        setLoading(false);
      }
    } catch (error) {
      toastNotification("error", "Something went wrong, Try again");
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (Array.isArray(values[key])) {
        formData.append(key, JSON.stringify(values[key]));
      } else {
        formData.append(key, values[key]);
      }
    });
    await handleFormSubmission(formData);
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Please fill all required fields correctly.");
  };

  return (
    <div style={{ maxWidth: 600, margin: "0 auto", padding: "20px" }}>
      <h2 style={{ textAlign: "center" }}>Company Onboarding</h2>
      <p style={{ textAlign: "center" }}>
        Enter your company details to get registered for this tool.
      </p>

      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{ technologyNiche: [] }}
      >
        <Form.Item
          label="Company Name"
          name="name"
          rules={[
            { required: true, message: "Please enter the company name" },
            { min: 3, message: "Name must be at least 3 characters" },
          ]}
        >
          <Input placeholder="Company Name" />
        </Form.Item>

        <Form.Item
          label="Establishment Year"
          name="establishmentYear"
          rules={[
            {
              required: true,
              message: "Please enter the establishment year",
            },
            {
              pattern: /^[0-9]{4}$/,
              message: "Please enter a valid year (e.g., 2022)",
            },
            {
              validator: (_, value) =>
                value && value > new Date().getFullYear()
                  ? Promise.reject(
                      "Year must be less than or equal to the current year"
                    )
                  : Promise.resolve(),
            },
          ]}
        >
          <Input placeholder="Year (e.g., 2022)" />
        </Form.Item>

        <Form.Item
          label="Company Email"
          name="companyEmail"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter a valid email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Please enter a password" },
            { min: 3, message: "Password must be at least 3 characters" },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="changepassword"
          dependencies={["password"]}
          rules={[
            { required: true, message: "Please confirm your password" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Passwords do not match!");
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item
          label="Company Strength"
          name="companyStrength"
          rules={[
            { required: true, message: "Please enter company strength" },
            {
              pattern: /^[0-9]+$/,
              message: "Strength must be a numeric value",
            },
          ]}
        >
          <Input placeholder="Company Strength" />
        </Form.Item>

        <Form.Item
          label="Company Website"
          name="companyWebsite"
          rules={[
            {
              required: true,
              message: "Please enter the company website",
            },
            {
              type: "url",
              message: "Please enter a valid website URL",
            },
          ]}
        >
          <Input placeholder="Website URL" />
        </Form.Item>

        <Form.Item
          label="Technology Niche"
          name="technologyNiche"
          rules={[
            {
              required: true,
              message: "Please select at least one technology niche",
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Select technology niche"
            allowClear
          >
            <Option value="AI">AI</Option>
            <Option value="Blockchain">Blockchain</Option>
            <Option value="IoT">IoT</Option>
            <Option value="Web Development">Web Development</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Contact Person Email"
          name="contactPerson"
          rules={[
            {
              required: true,
              message: "Please enter the contact person's email",
            },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input placeholder="Contact Person Email" />
        </Form.Item>

        <Form.Item
          label="Payment Cycle (in days)"
          name="paymentCycle"
          rules={[
            { required: true, message: "Please enter the payment cycle" },
            {
              pattern: /^[0-9]+$/,
              message: "Payment cycle must be a numeric value",
            },
          ]}
        >
          <Input placeholder="Payment Cycle (e.g., 30)" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: "100%" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CompanyRegistration;
