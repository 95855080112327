import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Layout, Button, Row, Col, Typography, Form, Input } from "antd";
import { toastNotification } from "../utils/toasthelper";
import { postReq } from "../api";
import OnBoardPage from "./Vendor/onBoardPage";
import UploadDocsForm from "./Vendor/uploadDoc";
import CompanyRegistration from "./companyRegistration";
import CandidateForm from "./candidateOnBoard";
import signinbg from "../assets/images/img-signin.jpg";

const { Title } = Typography;
const { Content } = Layout;

const NewSignIn = () => {
  const location = useLocation();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState("");
  const [vendorId, setVendorId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  // Extract vendor ID and determine the current page
  useEffect(() => {
    const path = location.pathname;

    if (path.includes("/auth/company/registration")) {
      setCurrentPage("companyRegistration");
    } else if (path.includes("/auth/company/login")) {
      setCurrentPage("companyLogin");
    } else if (path.includes("/auth/company/user/login")) {
      setCurrentPage("companyUserLogin");
    } else if (path.includes("/auth/vendor/login")) {
      setCurrentPage("vendorLogin");
    } else if (path.includes("/auth/vendor-onboarding")) {
      setCurrentPage("vendorOnboard");

      // Extract the vendor ID from the URL
      const segments = path.split("/");
      const idIndex = segments.findIndex(
        (segment) => segment === "vendor-onboarding"
      );
      if (idIndex !== -1 && segments[idIndex + 1]) {
        setVendorId(segments[idIndex + 1]);
      }
    } else if (path.includes("/auth/upload-signed-doc")) {
      setCurrentPage("uploadSignDoc");
    } else if (path.includes("/auth/candidate/create")) {
      setCurrentPage("candidateOnBoard");
    } else {
      setCurrentPage("default");
    }
  }, [location.pathname]);
  console.log("companyId", vendorId);
  const handleLogin = async (values) => {
    setLoading(true);
    try {
      const endpoint =
        currentPage === "companyLogin"
          ? "/company/login"
          : currentPage === "vendorLogin"
          ? "/vendor/login"
          : currentPage === "companyUserLogin"
          ? "/company/user/login"
          : null;

      const login = await postReq(endpoint, values);

      if (login.status === 1) {
        toastNotification("success", login.message);
        const { userData, token, roleId } = login.data;
        if (currentPage === "companyLogin") {
          localStorage.setItem("userRole", "company");
          localStorage.setItem("companyToken", token);
          localStorage.setItem("companyDetails", JSON.stringify(userData));
          window.location.replace("/company/dashboard");
        } else if (currentPage === "vendorLogin") {
          localStorage.setItem("userRole", "vendor");
          localStorage.setItem("vendorToken", token);
          localStorage.setItem("vendorDetails", JSON.stringify(userData));
          window.location.replace("/vendor/requirement");
        } else if (currentPage === "companyUserLogin") {
          localStorage.setItem("userDetails", JSON.stringify(login.data));
          localStorage.setItem("userToken", login.data.token);
          localStorage.setItem("userRole", login?.data?.roleId.slug);
          window.location.replace("/company/TimeSheet");
        }
      } else {
        toastNotification("error", login.message);
      }
    } catch (error) {
      toastNotification("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout className="layout-default layout-signin">
      <Content className="signin">
        <Row gutter={[24, 0]} justify="space-around">
          <Col xs={{ span: 24 }} lg={{ span: 6 }} md={{ span: 12 }}>
            <Title className="mb-15">Sign In</Title>
            <Title className="font-regular text-muted" level={5}>
              Enter your email and password to sign in
            </Title>

            {/* Dynamic Page Rendering */}
            {currentPage === "companyRegistration" && <CompanyRegistration />}
            {currentPage === "vendorOnboard" && (
              <OnBoardPage vendorId={vendorId} />
            )}
            {currentPage === "uploadSignDoc" && <UploadDocsForm />}
            {currentPage === "candidateOnBoard" && <CandidateForm />}
            {(currentPage === "companyLogin" ||
              currentPage === "vendorLogin" ||
              currentPage === "companyUserLogin") && (
              <Form
                form={form}
                onFinish={handleLogin}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={loading}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>

                <p className="font-semibold text-muted">
                  Don't have an account?{" "}
                  <Link
                    to="/auth/company/registration"
                    className="text-dark font-bold"
                  >
                    Sign Up
                  </Link>
                </p>
              </Form>
            )}
          </Col>
          <Col
            className="sign-img"
            style={{ padding: 12 }}
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
          >
            <img src={signinbg} alt="" />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default NewSignIn;
