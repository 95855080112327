import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Spin } from "antd";
import VendorDashboard from "./pages/Vendor/VendorDashboard";
import JobCard from "./pages/Vendor/vendorRequirementDesc";
import YourApplication from "./pages/Vendor/YourApplication";
import CandidateForm from "./pages/candidateOnBoard";
import Invoice from "./pages/Invoice";
import InvoiceForm from "./pages/autoGenerateInvoice";
import UpdateInvoiceForm from "./pages/updateInvoice";
import TimeSheet from "./pages/TimeSheet";

export default function BasePage(props) {
  return (
    <Suspense
      fallback={
        <Spin
          size="large"
          style={{ display: "block", margin: "auto", marginTop: "20%" }}
        />
      }
    >
      <Switch>
        {/* Redirect from root URL to appropriate dashboard */}
        {props.userRole === "company" ? (
          <Redirect exact from="/" to="/company/dashboard" />
        ) : props.userRole === "vendor" ? (
          <Redirect exact from="/" to="/vendor/requirement" />
        ) : (
          <Redirect exact from="/" to="/company/requirement" />
        )}

        {props.CompanyRoutesConfig.length > 0 &&
          props.CompanyRoutesConfig.map((route, index) => (
            <Route path={route.path} component={route.component} key={index} />
          ))}
        <Route path="/vendor/requirement" component={VendorDashboard} />
        <Route path="/vendor/requirement_description" component={JobCard} />
        <Route path="/vendor/applications" component={YourApplication} />
        {/* <Route path="/vendor/Candidate/create" component={CandidateForm} /> */}
        <Route path="/vendor/invoices" component={Invoice} />
        <Route path="/vendor/generate_invoices" component={InvoiceForm} />
        <Route path="/vendor/update_invoices" component={UpdateInvoiceForm} />
        <Route path="/vendor/timesheet" component={TimeSheet} />

        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
}
