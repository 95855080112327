import toast from "react-hot-toast";

export const toastNotification = (type, message) => {
  const options = {
    duration: 4000, // Customize the duration as needed
    position: "top-right", // You can change the position if necessary
  };

  if (type === "success") {
    toast.success(message, {
      ...options,
      style: {
        background: "#00C851", // Success background color
        color: "#fff", // Success text color
      },
    });
  } else if (type === "error") {
    toast.error(message, {
      ...options,
      style: {
        background: "#ff4444", // Error background color
        color: "#fff", // Error text color
      },
    });
  } else {
    toast(message, options);
  }
};
