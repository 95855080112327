import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Typography,
  Card,
  InputNumber,
} from "antd";
import CardComponent from "../components/cardComponent";
import { postReq } from "../api";
import { Link } from "react-router-dom";
import { notification } from "antd";
const { Option } = Select;

const { Title, Text } = Typography;

const Requirements = () => {
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    searchParams: "",
    status: "open",
  });
  const [jobData, setJobData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [requiredTechOptions, setRequiredTechOptions] = useState([]);
  const [techNicheOptions, setTechNicheOptions] = useState([]);
  const [isSearch, setIsSearch] = useState(true);

  const requirementTypeOptions = [
    {
      value: "Fte",
      _id: "Fte",
    },
    {
      value: "Contractual",
      _id: "Contractual",
    },
  ];

  const handleTagsData = async (isRequiredTech) => {
    try {
      let { _id } = JSON.parse(localStorage.getItem("companyDetails"));
      let type = isRequiredTech ? "requiredTech" : "techNiche";

      const callResponse = await postReq("/common/getTags", {
        type: type,
        companyId: _id,
      });

      if (callResponse.status == 1) {
        if (isRequiredTech) {
          setRequiredTechOptions(callResponse.data);
          console.log("call respnosea required tech ", callResponse.data);
        } else {
          setTechNicheOptions(callResponse.data);
          console.log("call response techNiche ", callResponse.data);
        }
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const isCompany = true;

  const getRequirementList = async (newQuery) => {
    try {
      setIsLoading(true);
      const getRes = await postReq("/company/requirement/list", newQuery);
      if (getRes.status == 1 && !Array.isArray(getRes.data)) {
        setJobData(getRes.data.requirements);
        console.log(getRes.data.requirements);
        setIsLoading(false);
      } else {
        setJobData([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error ", error);
      setJobData([]);
      setIsLoading(false);
    }
  };
  console.log("jobData", jobData);

  useEffect(() => {
    getRequirementList(query);
    handleTagsData(true);
    handleTagsData(false);
  }, []);

  const [form] = Form.useForm();

  const handleClick = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    try {
      const postData = await postReq("/company/requirement/create", values, {});
      console.log(postData);
      if (postData.status == 1) {
        getRequirementList(query);
        form.resetFields();
        setShowModal(false);

        // Success notification
        notification.success({
          message: "Success",
          description: "The operation was successful.",
          placement: "topRight",
        });
      } else {
        form.resetFields();
        setShowModal(false);
        // Error notification
        notification.error({
          message: "Error",
          description:
            postData.message || "There was an error processing your request.",
          placement: "topRight",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description:
          error.message || "There was an error processing your request.",
        placement: "topRight",
      });
      form.resetFields();
      setShowModal(false);
    }
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      getRequirementList(query);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [query.searchParams]);
  const handleOnChange = (e) => {
    setQuery({ ...query, searchParams: e.target.value });
  };

  return (
    <>
      <Modal
        title="Post Your Requirement"
        open={showModal}
        onCancel={handleCancel}
        footer={null}
        width="70%" // Full width on moblepx
        style={{ top: 20 }}
        bodyStyle={{ padding: 10 }}
        responsive={true}
        className="responsive-modal"
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Requirement Name"
                name="requirementName"
                rules={[
                  { required: true, message: "Please enter requirement name" },
                ]}
              >
                <Input placeholder="Enter" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Select Requirement Type"
                name="requirementType"
                rules={[
                  { required: true, message: "Please select requirement type" },
                ]}
              >
                <Select
                  placeholder="Select"
                  className="ant-select-selection1"
                  // dropdownStyle={{
                  //   backgroundColor: "#fff",
                  //   borderRadius: "8px",
                  // }}
                  // dropdownClassName="custom-dropdown"
                >
                  {requirementTypeOptions.length > 0 &&
                    requirementTypeOptions.map((data, i) => (
                      <Option key={i} value={`${data._id}`}>
                        {data.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Select Technology Niche"
                name="requirementNiche"
                rules={[
                  { required: true, message: "Please select technology niche" },
                  {
                    validator: (_, value) => {
                      if (!value || value.length < 1) {
                        return Promise.reject(
                          new Error("At least one niche is required")
                        );
                      }
                      if (value.length > 10) {
                        return Promise.reject(
                          new Error("You can add at most 10 niches")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Select
                  mode="multiple" // Enables multi-select
                  placeholder="Technology Niche"
                  allowClear
                  style={{ width: "100%" }}
                >
                  {techNicheOptions.length > 0 &&
                    techNicheOptions.map((data, i) => (
                      <Option key={i} value={`${data._id}`}>
                        {data.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Select Required Technology"
                name="requiredTechnology"
                rules={[
                  {
                    required: true,
                    message: "Please select required technology",
                  },
                  {
                    validator: (_, value) => {
                      if (!value || value.length < 1) {
                        return Promise.reject(
                          new Error("At least one technology is required")
                        );
                      }
                      if (value.length > 10) {
                        return Promise.reject(
                          new Error("You can select at most 10 technologies")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Select
                  mode="multiple" // Enables multi-select
                  placeholder="Required Technology"
                  allowClear // Optionally allows clearing the selection
                  style={{ width: "100%" }} // Ensure the width is 100% for better UX
                >
                  {requiredTechOptions.length > 0 &&
                    requiredTechOptions.map((data, i) => (
                      <Option key={i} value={`${data._id}`}>
                        {data.value}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Enter Minimum Experience"
                name="minExperience"
                rules={[
                  {
                    required: true,
                    message: "Please enter minimum experience",
                  },
                  {
                    type: "number",
                    min: 1,
                    message: "Minimum Experience should be at least 1 year",
                  },
                  {
                    type: "number",
                    max: 15,
                    message:
                      "Minimum Experience should be less than or equal to 14 years",
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  max={14}
                  placeholder="Enter"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Enter Maximum Experience"
                name="maxExperience"
                rules={[
                  {
                    required: true,
                    message: "Please enter maximum experience",
                  },
                  {
                    type: "number",
                    min: 1,
                    message: "Maximum Experience should be at least 1 year",
                  },
                  {
                    type: "number",
                    max: 18,
                    message:
                      "Maximum Experience should be less than or equal to 18 years",
                  },
                ]}
              >
                <InputNumber placeholder="Enter" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Enter Job Location"
                name="jobLocation"
                rules={[
                  { required: true, message: "Please enter job location" },
                ]}
              >
                <Input placeholder="Enter" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Enter Budget"
                name="budget"
                rules={[{ required: true, message: "Please enter budget" }]}
              >
                <Input placeholder="Enter" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Enter Job Description"
            name="requirementJobDescription"
            rules={[
              { required: true, message: "Please enter job description" },
              {
                min: 100,
                message: "Description must be at least 100 characters",
              },
              { max: 500, message: "Description cannot exceed 500 characters" },
            ]}
          >
            <Input.TextArea maxLength={500} placeholder="Enter" />
          </Form.Item>

          <Form.Item>
            <Button
              type="default"
              onClick={handleCancel}
              style={{ marginRight: 8 }}
            >
              Reset
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Card
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px",
              borderRadius: "20px 20px 0px 0px",
              margin: "0px -24px",
            }}
          >
            <div>
              <Title
                level={4}
                style={{
                  marginBottom: 0,
                  fontSize: "20px",
                  fontWeight: "800",
                  color: "#13487D",
                }}
              >
                Requirements
              </Title>
              <Text type="secondary" style={{ fontSize: "14px" }}>
                Dedicated Project
              </Text>
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              {isSearch ? (
                <svg
                  width="44"
                  height="44"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setIsSearch(!isSearch)}
                >
                  <rect width="64" height="64" rx="18" fill="#F4F4F4" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M35.2267 37.1801L40.6511 42.5978C41.1882 43.1342 42.0603 43.134 42.5974 42.5975C43.1343 42.0607 43.1342 41.1893 42.5971 40.6527L37.1763 35.2389C38.2668 33.757 38.9114 31.9269 38.9114 29.9476C38.9114 25.0092 34.8985 21 29.9557 21C25.0129 21 21 25.0092 21 29.9476C21 34.8859 25.0129 38.8951 29.9557 38.8951C31.9255 38.8951 33.7474 38.2586 35.2267 37.1801ZM29.9557 23.7501C33.3793 23.7501 36.159 26.5271 36.159 29.9476C36.159 33.3681 33.3793 36.1452 29.9557 36.1452C26.5321 36.1452 23.7526 33.3681 23.7526 29.9476C23.7526 26.5271 26.5321 23.7501 29.9557 23.7501Z"
                    fill="#13487D"
                  />
                </svg>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#F4F4F4",
                    borderRadius: "10px",
                    // padding: "10px 16px",
                    width: "300px",
                    boxShadow: "0px 1px 5px rgba(0,0,0,0.1)",
                  }}
                >
                  <Input
                    placeholder="Search By Job Name"
                    bordered={false}
                    onChange={handleOnChange}
                    style={{
                      fontSize: "16px",
                      color: "#6F6F6F",
                      border: "none",
                    }}
                  />
                  <svg
                    width="44"
                    height="44"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setIsSearch(!isSearch)}
                  >
                    <rect width="64" height="64" rx="18" fill="#F4F4F4" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M35.2267 37.1801L40.6511 42.5978C41.1882 43.1342 42.0603 43.134 42.5974 42.5975C43.1343 42.0607 43.1342 41.1893 42.5971 40.6527L37.1763 35.2389C38.2668 33.757 38.9114 31.9269 38.9114 29.9476C38.9114 25.0092 34.8985 21 29.9557 21C25.0129 21 21 25.0092 21 29.9476C21 34.8859 25.0129 38.8951 29.9557 38.8951C31.9255 38.8951 33.7474 38.2586 35.2267 37.1801ZM29.9557 23.7501C33.3793 23.7501 36.159 26.5271 36.159 29.9476C36.159 33.3681 33.3793 36.1452 29.9557 36.1452C26.5321 36.1452 23.7526 33.3681 23.7526 29.9476C23.7526 26.5271 26.5321 23.7501 29.9557 23.7501Z"
                      fill="#13487D"
                    />
                  </svg>
                </div>
              )}
              <svg
                width="44"
                height="44"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleClick}
              >
                <rect width="64" height="64" rx="18" fill="#F4F4F4" />
                <path
                  d="M32 22V42"
                  stroke="#13487D"
                  stroke-width="2.7"
                  stroke-linecap="round"
                />
                <path
                  d="M42 32L22 32"
                  stroke="#13487D"
                  stroke-width="2.7"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
        }
        style={{ paddingLeft: "16px", color: "#13487D" }}
      >
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          {jobData.map((data, i) => (
            <Col
              className="gutter-row"
              xs={32}
              sm={16}
              md={8}
              lg={8}
              style={{ marginBottom: "20px" }}
            >
              <Link
                to={{
                  pathname: "/company/requirement_description",
                  state: {
                    data: data,
                  },
                }}
              >
                <CardComponent
                  key={i}
                  requirementName={data.requirementName}
                  jobId={data.jobId}
                  requirementType={data.requirementType}
                  maxExperience={data.maxExperience}
                  minExperience={data.minExperience}
                  jobLocation={data.jobLocation}
                  requiredTechnology={data.requiredTechnology}
                />
              </Link>
            </Col>
          ))}
        </Row>
      </Card>
    </>
  );
};

export default Requirements;
