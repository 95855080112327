import React from "react";
import { Card, Typography, Tag, Button, Space, Tooltip } from "antd";
const { Title, Text } = Typography;

const CardComponent = ({
  requirementName,
  jobId,
  requirementType,
  maxExperience,
  minExperience,
  jobLocation,
  requiredTechnology,
}) => {
  const firstTwoTechnology = requiredTechnology.slice(0, 2);
  const remainingTechnologyCount = requiredTechnology.length - 2;
  const remainingTechnology = requiredTechnology.slice(
    2,
    requiredTechnology.length
  );
  return (
    <Card
      className="cardComponent"
      style={{
        height: "100%",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "20px",
      }}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#DBDBDB",
            padding: "16px",
            borderRadius: "20px 20px 0px 0px",
            margin: "0px , -24px",
          }}
        >
          <div>
            <Title
              level={4}
              style={{
                marginBottom: 0,
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              {requirementName}
            </Title>
            <Text type="secondary" style={{ fontSize: "14px" }}>
              Dedicated Project
            </Text>
          </div>
          <div>
            <Title
              level={4}
              style={{
                marginBottom: 0,
                color: "#13487D",
                fontWeight: "800",
              }}
            >
              $55/<span style={{ fontSize: "16px" }}>day</span>
            </Title>
          </div>
        </div>
      }
    >
      {/* Job Title and Price */}

      <div style={{ marginTop: 4 }}>
        {/* Job ID */}
        <Tag
          color="#E7F1FC"
          style={{
            border: "2px solid #C1D6EE",
            borderRadius: "16px",
            padding: "2px 10px",
            fontSize: "12px",
          }}
        >
          <span style={{ color: "#13487D", fontWeight: "600" }}>
            Job ID {jobId}
          </span>
        </Tag>

        {/* Job Type */}
        <div style={{ marginTop: 16, fontSize: "12px" }}>
          <Text>
            <span style={{ color: "#666666" }}>Job Type</span>
            <strong> {requirementType}</strong>
          </Text>
        </div>

        {/* Experience */}
        <div style={{ marginTop: 12, fontSize: "12px" }}>
          <Text>
            <span style={{ color: "#666666" }}>Experience</span>
            <strong>{` ${minExperience} to ${maxExperience} year`}</strong>
          </Text>
        </div>

        {/* Location */}
        <div style={{ marginTop: 12, fontSize: "12px" }}>
          <Text>
            <span style={{ color: "#666666" }}>Location</span>
            <strong> {jobLocation}</strong>
          </Text>
        </div>

        {/* Skills */}
        <div
          style={{
            marginTop: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Text
            style={{
              whiteSpace: "nowrap", // Prevent the word "Skill" from breaking
            }}
          >
            <span style={{ color: "#666666", marginRight: "6px" }}>Skill </span>
          </Text>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Space
              size={"small"}
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {firstTwoTechnology?.map((data) => (
                <Tag
                  style={{
                    borderRadius: "20px",
                    color: "#13487D",
                    fontWeight: "600",
                    border: "2px solid #C1D6EE",
                  }}
                >
                  {data?.value}
                </Tag>
              ))}
              {remainingTechnologyCount > 0 && (
                <Tooltip
                  placement="top"
                  title={
                    <div
                      style={{
                        gap: "4px",
                        maxWidth: "200px",
                        wordWrap: "break-word",
                      }}
                    >
                      {remainingTechnology.map((data, i) => (
                        <span key={i} style={{ whiteSpace: "nowrap" }}>
                          <p style={{ fontSize: "12px", margin: 0 }}>
                            {data.value}
                          </p>
                        </span>
                      ))}
                    </div>
                  }
                >
                  <Button
                    type="primary"
                    shape="circle"
                    style={{
                      backgroundColor: "#1A90FF",
                      border: "none",
                      borderRadius: "16px",
                      width: "18px",
                      height: "22px",
                      fontWeight: "bold",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "0px 4px 6px rgb(26,144,255,0.3)",
                    }}
                  >
                    +{remainingTechnologyCount}
                  </Button>
                </Tooltip>
              )}
            </Space>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CardComponent;
